import React, { useState, useEffect } from "react";
import Layout from "../../components/shared-layout";
import BuildIcon from '@material-ui/icons/Build';
import { observer } from "mobx-react";
import { Button, CircularProgress, MenuItem, Select, Tab, Tabs } from "@material-ui/core";
import TabPanel from "../../components/tab-panel";
import useStores from "../../hooks/use-stores";
import { useTranslation } from 'react-i18next';
import ExternalTab from "../../components/academics/external-tab";
import { getPermissionValidity, PermissionCategory } from "../../constants/options";

function tabProps(index: any, isActive) {
  return {
    id: `external-tab-${index}`,
    'aria-controls': `external-tabpanel-${index}`,
    className: `normal-case ${isActive ? 'text-barPrimary font-bold' : 'text-black'} `
  };
}

const Filter = observer(({ callback }) => {
  const { filterStore } = useStores();
  const { t } = useTranslation();
  const [year, setYear] = useState<string>("");
  const [semester, setSemester] = useState(0);
  const [semesterDescription, setSemesterDescription] = useState<string>("");
  const [semesterList, setSemesterList] = useState<any>([]);
  const [startDate, setStartDate] = useState<any>(new Date());
  const [endDate, setEndDate] = useState<any>(new Date());
  const [classCode, setClassCode] = useState<any>(0);
  const DEFAULT_YEAR = new Date().getFullYear().toString();

  const onChangeYear = (event: React.ChangeEvent<{ value: unknown }>) => {
    setYear(event.target.value as string);
    setSemester(0);
    setSemesterList([]);
    setSemesterDescription(t('ALL'));
    filterStore.getSemesterBasedOnYear(event.target.value as string);
    if (filterStore.semesterList.length > 0) {
      var found = false;
      for (var sem of filterStore.semesterList) {
        setSemester(sem.id);
        setClassCode(0);
        setSemesterList([sem.id])
        var selectedSemester = filterStore.semesterList.find(e => e.id == sem.id);
        var startTerm = selectedSemester.academicTerms[0].academicTermDates.find(e => e.yearLevelId > 0);
        var endTerm = selectedSemester.academicTerms[1].academicTermDates.find(e => e.yearLevelId > 0);
        setStartDate(startTerm.startDate);
        setEndDate(endTerm.endDate);
        found = true;
        break;
      }
      if (!found) {
        setSemester(0);
        setClassCode(0);
        setSemesterList([]);
      }
    }
  }

  useEffect(() => {
    // filterStore.getSemesterDateList().then(() => {
    setYear(DEFAULT_YEAR);
    if (filterStore.academicYearList.length > 0) {
      for (var ay of filterStore.academicYearList) {
        if (ay.yearDescription == DEFAULT_YEAR) {
          setYear(ay.yearDescription);
          filterStore.getSemesterBasedOnYear(ay.yearDescription);
          break;
        }
      }
      filterStore.getCurrentSemester();
      setSemester(filterStore.currentSemester);
      setSemesterList([filterStore.currentSemester]);
      var selectedSemester = filterStore.semesterList.find(e => e.id == filterStore.currentSemester);
      setSemesterDescription(selectedSemester.semesterDescription);
      if (selectedSemester.academicTerms[0].academicTermDates.length > 0) {
        var startTerm = selectedSemester.academicTerms[0].academicTermDates.find(e => e.yearLevelId > 0);
        var endTerm = selectedSemester.academicTerms[1].academicTermDates.find(e => e.yearLevelId > 0);
        setStartDate(startTerm.startDate);
        setEndDate(endTerm.endDate);
        callback({
          year: DEFAULT_YEAR,
          semester: filterStore.currentSemester,
          semesterList: filterStore.currentSemester,
          semesterDescription: selectedSemester.semesterDescription,
          startDate: startTerm.startDate,
          endDate: endTerm.endDate,
          classCode: filterStore.filteredHomeRoomCodeList.map(e => e.homeroomCode),
        });
      }
    } else {
      filterStore.getSemesterDateList().then(() => {
        for (var ay of filterStore.academicYearList) {
          if (ay.yearDescription == DEFAULT_YEAR) {
            setYear(ay.yearDescription);
            filterStore.getSemesterBasedOnYear(ay.yearDescription);
            break;
          }
        }
        filterStore.getCurrentSemester();
        setSemester(filterStore.currentSemester);
        setSemesterList([filterStore.currentSemester]);
        filterStore.getHomeRoomCodeList().then(() => {
          filterStore.getHomeRoomCodeListBySemesterId([filterStore.currentSemester]);
          var selectedSemester = filterStore.semesterList.find(e => e.id == filterStore.currentSemester);
          setSemesterDescription(selectedSemester.semesterDescription);
          if (selectedSemester.academicTerms[0].academicTermDates.length > 0) {
            var startTerm = selectedSemester.academicTerms[0].academicTermDates.find(e => e.yearLevelId > 0);
            var endTerm = selectedSemester.academicTerms[1].academicTermDates.find(e => e.yearLevelId > 0);
            setStartDate(startTerm.startDate);
            setEndDate(endTerm.endDate);
            callback({
              year: DEFAULT_YEAR,
              semester: filterStore.currentSemester,
              semesterList: filterStore.currentSemester,
              semesterDescription: selectedSemester.semesterDescription,
              startDate: startTerm.startDate,
              endDate: endTerm.endDate,
              classCode: filterStore.filteredHomeRoomCodeList.map(e => e.homeroomCode),
            });
          }
        })

      });
    }

    // });
  }, []);

  const onChangeSemster = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSemester(event.target.value as number);
    setSemesterList([event.target.value as number]);
    setClassCode(0);
    if (event.target.value != 0) {
      var selectedSemester = filterStore.semesterList.find(e => e.id == (event.target.value as number));
      setSemesterDescription(selectedSemester.semesterDescription);
      if (selectedSemester.academicTerms[0].academicTermDates.length > 0) {
        var startTerm = selectedSemester.academicTerms[0].academicTermDates.find(e => e.yearLevelId > 0);
        var endTerm = selectedSemester.academicTerms[1].academicTermDates.find(e => e.yearLevelId > 0);
        setStartDate(startTerm.startDate);
        setEndDate(endTerm.endDate);
      }
    } else {
      if (filterStore.semesterList.length > 0) {
        let semList = [filterStore.semesterList[0].id, filterStore.semesterList[1].id];
        setSemesterList(semList);
        setSemesterDescription(t('ALL'));
        // ===== First Sem ===== //
        var firstSem = filterStore.semesterList.find(e => e.id == semList[0]);
        if (firstSem.academicTerms[0].academicTermDates.length > 0) {
          var startTerm = firstSem.academicTerms[0].academicTermDates.find(e => e.yearLevelId > 0);
          setStartDate(startTerm.startDate);
        }
        // ===== Sec Sem ===== //
        var secSem = filterStore.semesterList.find(e => e.id == semList[1]);
        if (secSem.academicTerms[0].academicTermDates.length > 0) {
          var endTerm = secSem.academicTerms[1].academicTermDates.find(e => e.yearLevelId > 0);
          setEndDate(endTerm.endDate);
        }
      }
    }
  }

  const onChangeClassCode = (event, child: any) => {
    filterStore.selectedHomeroom = child.props.children;
    if (child.props.value != 0) setClassCode([child.props.value]);
    else setClassCode(0);
  }

  return (
    <div className="shadow-lg rounded-xl border">
      <div className="grid grid-cols-8 gap-4 p-4">
        <div className="col-span-2 flex">
          <div className="text-barPrimary mr-2 mt-1">
            {t('YEAR')}
          </div>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            className="bg-background w-24 px-2"
            style={{ height: 30 }}
            value={year}
            onChange={onChangeYear}
          >
            {
              filterStore.academicYearList &&
              filterStore.academicYearList.map((ay, index) => (
                <MenuItem key={`academic_year_list_${ay.id}_${index}`} value={ay.yearDescription}>
                  {ay.yearDescription}
                </MenuItem>
              ))
            }
          </Select>
        </div>

        <div className="col-span-2 flex">
          <div className="text-barPrimary mr-2 mt-1">
            {t('SEMESTER')}
          </div>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            className="bg-background w-24 px-2"
            style={{ height: 30 }}
            value={semester}
            onChange={onChangeSemster}
            disabled={year == ""}
          >
            <MenuItem value={0}>{t('ALL')}</MenuItem>
            {
              filterStore.semesterList.length > 0 &&
              filterStore.semesterList.map((sem, index) => (
                <MenuItem key={`semester_list_${sem.id}_${index}`} value={sem.id}>
                  {sem.semesterDescription.substring(8, 10)}
                </MenuItem>
              ))
            }
          </Select>
        </div>

        <div className="col-span-3 flex">
          <div className="text-barPrimary mr-2 mt-1">
            {t('HOMEROOM_CODE')}
          </div>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            className="bg-background w-24 px-2"
            onChange={onChangeClassCode}
            value={classCode}
          >
            <MenuItem value={0}>{t('ALL')}</MenuItem>
            {
              filterStore.filteredHomeRoomCodeList.length > 0 &&
              filterStore.filteredHomeRoomCodeList.map((hr, index) => (
                <MenuItem key={`homeroom_code_list_${hr.homeroomCode}_${index}`} value={hr.homeroomCode}>
                  {hr.homeroomCode}
                </MenuItem>
              ))
            }
          </Select>
        </div>

        <div className="w-full text-right">
          <Button
            className="bg-buttonPrimary text-white text-sm"
            onClick={() => {
              callback({
                year,
                semesterList,
                startDate,
                endDate,
                classCode: classCode != 0 ? classCode : filterStore.filteredHomeRoomCodeList.map(e => e.homeroomCode),
                semesterDescription
              })
            }}
          >
            {t('SEARCH')}
          </Button>
        </div>
      </div>
    </div>
  );
});

const AcademicsExternal = observer((() => {
  const { studentStore, academicStore } = useStores();
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState<number>(0);
  const [displayFilterTitle, setDisplayFilterTitle] = useState<string>("");
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };
  const onApplyFilter = (dataInfo: any,) => {
    console.log(dataInfo);
    setDisplayFilterTitle(
      `${t('YEAR')} ${dataInfo.year} - ${dataInfo.semesterDescription} - ${dataInfo.classCode.length > 1 ? `${t('HOMEROOM_CODE')}: ${t('ALL')}` : dataInfo.classCode}`);
  }
  return (
    <>
      {
        typeof window !== "undefined" &&
        <Layout
          showSeachStudent={true}
          showFilter={false}
          showYearSemTerm={true}
          showClassCode={true}
          showDomain={true}
          showStaffList={false}
          showNewsArea={true}
          onApplyFilter={onApplyFilter}
        >
          <title>{t('ACADEMICS')}</title>
          <div className="col-span-4">
            {/* Overview */}
            <div className="m-2 mb-0 rounded-t-xl border">
              <div className="text-barPrimary text-lg font-bold p-4 uppercase">{t('ACADEMICS')}</div>
            </div>

            <Tabs
              value={tabValue}
              onChange={handleChange}
              aria-label="external tabs example"
              TabIndicatorProps={{ style: { display: "none" } }}
              className="border border-t-0 rounded-b-xl shadow-lg mx-2 mb-4"
            >
              {/* {getPermissionValidity(PermissionCategory.SCHOOL, "Academic", "AtSchool") &&
                <Tab label={t('AT_SCHOOL')} {...tabProps(0, 0 === tabValue)} />} */}
              {getPermissionValidity(PermissionCategory.SCHOOL, "Academic", "External") &&
                <Tab label={t('EXTERNAL')} {...tabProps(0, 0 === tabValue)} />
              }
            </Tabs>
{/* 
            <TabPanel value={tabValue} index={0} paddingValue={0}>
              <Filter callback={onApplyFilter} />
              <div className="shadow-lg border rounded-xl my-4 p-4">
                <div className="text-barPrimary uppercase font-bold">{displayFilterTitle}</div>
              </div>
            </TabPanel> */}

            <TabPanel value={tabValue} index={0} paddingValue={0}>
              <ExternalTab />
            </TabPanel>

          </div>

        </Layout>
      }
    </>
  )
}));

export default AcademicsExternal