import React, { useState, useEffect } from "react";
import Layout from "../shared-layout";
import BuildIcon from '@material-ui/icons/Build';
import { observer } from "mobx-react";
import { Button, CircularProgress, MenuItem, Select, Tab, Tabs } from "@material-ui/core";
import TabPanel from "../tab-panel";
import useStores from "../../hooks/use-stores";
import IESRadar from "../charts/iesRadar";
import { toJS } from "mobx";
import { THEME_CLASS, useStyles } from "../../constants/style";
import IESVBar from "../charts/iesVBar";
import { useTranslation } from 'react-i18next';
import VMCard from "../collapsed-card";

function tabProps(index: any, isActive) {
  return {
    id: `external-tab-${index}`,
    'aria-controls': `external-tabpanel-${index}`,
    className: `normal-case ${isActive ? 'text-barPrimary font-bold' : 'text-black'} `
  };
}

const PATTab = observer((() => {
  const { academicStore } = useStores();
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState<number>(0);
  const [patOverviewData, setPATOverviewData] = useState<any>([]);
  const [patMathLegend, setPatMathLegend] = useState<any>([]);
  const [patMathIndicator, setPatMathIndicator] = useState<any>();
  const [patMathData, setPatMathData] = useState<any>();
  const [patMathSelectList, setPatMathSelectList] = useState<any>([]);
  const [patMathSelect, setPatMathSelect] = useState<number>(0);
  const [patReadLegend, setPatReadLegend] = useState<any>([]);
  const [patReadIndicator, setPatReadIndicator] = useState<any>();
  const [patReadData, setPatReadData] = useState<any>();
  const [patReadSelectList, setPatReadSelectList] = useState<any>([]);
  const [patReadSelect, setPatReadSelect] = useState<number>(0);

  useEffect(() => {
    if (academicStore.patOverviewData.length > 0) {
      setPATOverviewData(academicStore.patOverviewData);
      // First Graph: PAT MATHS
      let mathPerformance = academicStore.patOverviewData[0].subjectPerformance;
      let validPerformanceList = mathPerformance.filter(data => data.performance.length > 0)
      setPatMathSelectList(validPerformanceList.map(data => data.number));
      setPatMathSelect(validPerformanceList.length > 0 ? validPerformanceList[0].number : 0);
      if (validPerformanceList && validPerformanceList.length > 0) {
        let indicator = [];
        let tempValue = [];
        for (var data of validPerformanceList[0].performance) {
          indicator.push({
            name: data.classificationName,
            max: 100,
          });
          tempValue.push(data.score);
        }
        setPatMathData([{
          name: "Score",
          value: tempValue
        }]);
        setPatMathLegend([`${academicStore.patOverviewData[0].subjectName}`]);
        setPatMathIndicator(indicator);
        console.log(indicator);

      }

      // First Graph: PAT Reading
      if (academicStore.patOverviewData.length > 1) {
        let readPerformance = academicStore.patOverviewData[1].subjectPerformance;
        let validPerformanceList = readPerformance.filter(data => data.performance.length > 0)
        setPatReadSelectList(validPerformanceList.map(data => data.number));
        setPatReadSelect(validPerformanceList.length > 0 ? validPerformanceList[0].number : 0);

        if (validPerformanceList && validPerformanceList.length > 0) {
          let indicator2 = [];
          let tempValue2 = [];
          for (var data of validPerformanceList[0].performance) {
            indicator2.push({
              name: data.classificationName,
              max: 100,
            });
            tempValue2.push(data.score);
          }
          setPatReadData([{
            name: "Score",
            value: tempValue2
          }]);
          setPatReadLegend([`${academicStore.patOverviewData[0].subjectName}`]);
          setPatReadIndicator(indicator2);
        }
      }
    }
  }, [academicStore.patOverviewData]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };


  const onChangeMathsSelection = (event) => {
    setPatMathSelect(event.target.value as number);
    let targetPerformance = patOverviewData[0].subjectPerformance.find(data => data.number == event.target.value);
    let tempValue = targetPerformance.performance.map(data => data.score);
    setPatMathData([{
      name: "Score",
      value: tempValue
    }]);
  }

  const onChangeReadSelection = (event) => {
    setPatReadSelect(event.target.value as number);
    let targetPerformance = patOverviewData[1].subjectPerformance.find(data => data.number == event.target.value);
    let tempValue = targetPerformance.performance.map(data => data.score);
    setPatReadData([{
      name: "Score",
      value: tempValue
    }]);
  }

  return (
    <VMCard titleComponent={
      <Tabs
        value={tabValue}
        onChange={handleChange}
        aria-label="external tabs example"
        TabIndicatorProps={{ style: { display: "none" } }}
        style={{ marginLeft: -5 }}
      >
        <Tab label={t('OVERVIEW')} {...tabProps(0, 0 == tabValue)} />
        <Tab label={t('DETAIL')} {...tabProps(1, 1 === tabValue)} />
      </Tabs>
    }>
      <TabPanel value={tabValue} paddingValue={0} index={0}>
        <div className="grid grid-cols-2 p-4 mt-4">
          {
            academicStore.loadingExternal ? <div className="col-span-2 w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
              :
              <div>
                {
                  patMathIndicator &&
                  <>
                    <div className="text-center">
                      <span className="mr-2">{t('NUMBER')}</span>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        className="bg-background w-20"
                        onChange={onChangeMathsSelection}
                        value={patMathSelect}
                      >
                        {patMathSelectList.map((m) => (
                          <MenuItem value={m}>{m}</MenuItem>
                        ))}
                      </Select>
                    </div>
                    <IESRadar
                      dataOptions={{
                        radius: '55%',
                        legend: patMathLegend,
                        indicator: patMathIndicator,
                        name: patMathLegend[0],
                        series: patMathData,
                      }}
                    />
                  </>
                }
                {
                  patReadIndicator &&
                  <div>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      className="bg-background w-20"
                      onChange={onChangeReadSelection}
                      value={patReadSelect}
                    >
                      {patReadSelectList.map((m) => (
                        <MenuItem value={m}>{m}</MenuItem>
                      ))}
                    </Select>
                    <IESRadar
                      dataOptions={{
                        radius: '55%',
                        legend: patReadLegend,
                        indicator: patReadIndicator,
                        name: patReadLegend[0],
                        series: patReadData,
                      }}
                    />
                  </div>
                }
              </div>
          }
        </div>
      </TabPanel>
      <TabPanel value={tabValue} paddingValue={1} index={1}>
        <PATDetail />
      </TabPanel>
    </VMCard>
  )
}));

const PATDetail = observer(() => {
  const { academicStore } = useStores();
  const { t } = useTranslation();
  const [activedDetail, setActivedDetail] = useState<any>();
  const [xAxisData, setXAxisData] = useState<any>([]);
  const [yAxisData, setYAxisData] = useState<any>([]);

  const onDisplaySingleDetail = (detail: any) => {
    setActivedDetail(detail);
    setXAxisData(detail.performance.map((data: any, index: number) => (
      {
        value: data.schoolAverageScore,
        itemStyle: {
          color: THEME_CLASS[0].chartColorLibrary[index % THEME_CLASS[0].chartColorLibrary.length]
        }
      }
    )));
    setYAxisData(detail.performance.map(data => data.classificationName));
  }

  return (
    <>
      {
        academicStore.loadingPATDetail ? <div className="col-span-2 w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
          :
          <>
            <table width="100%">
              <thead className="bg-barPrimary text-white text-sm text-center">
                <td className="border-r p-2">{t('NUMBER')}</td>
                <td className="border-r p-2">{t('TYPE')}</td>
                <td className="border-r p-2">{t('SCORE')}</td>
                <td className="border-r p-2">{t('ACTION')}</td>
              </thead>
              <tbody className="text-sm text-center">
                {
                  academicStore.patOverviewDetail.length > 0 &&
                  academicStore.patOverviewDetail.map((detail, index) => (
                    <tr key={`pat_detail_data_${index}`} className={`${index % 2 != 0 && 'bg-gray-50'}`}>
                      <td className="p-2">{detail.number}</td>
                      <td className="p-2">{detail.name}</td>
                      <td className="p-2">{detail.score}</td>
                      <td className="p-2">
                        <Button
                          className="bg-buttonPrimary text-white text-sm"
                          component="span"
                          onClick={() => onDisplaySingleDetail(
                            academicStore.patOverviewDetail.find(data => data.name == detail.name
                            ))}
                        >
                          {t('VIEW')}
                        </Button>
                      </td>
                    </tr>
                  ))

                }
              </tbody>
            </table>

            {
              activedDetail &&
              <>
                <div className="my-4 py-4 border-t">
                  <span className="font-bold">{t('TYPE')}: <span className="text-barPrimary">{activedDetail.name}</span></span>
                </div>
                <div className="text-center text-barPrimary text-lg mb-4">{t('SCHOOL_PERFORMANCE_IN_THIS_TEST')}</div>
                <IESVBar
                  dataAndSetting={{
                    data: {
                      names: yAxisData.length > 0 ? yAxisData : ['No Result'],
                      values: xAxisData.length > 0 ? xAxisData : [0],
                    },
                    height: 300,
                    width: "100%",
                    showDataZoom: false,
                    xAxisLabelRotate: 0,
                    barMaxWidth: 30,
                    yMax: 100,
                  }}
                />

                <table width="100%">
                  <thead className="bg-barPrimary text-white text-center text-sm">
                    <td className="border-r p-2">{t('QUESTION_NO')}</td>
                    <td className="border-r p-2">Strand</td>
                    <td className="border-r p-2">{t('PERCENTAGE_CORRECT')}</td>
                    <td className="border-r p-2">{t('QUESTION_DIFFICULTY')}</td>
                    <td className="border-r p-2">{t('CORRECT_ANSWER')}</td>
                  </thead>
                  <tbody className="text-center">
                    {
                      activedDetail.response.length > 0 &&
                      activedDetail.response.map((response, index) => (
                        <tr key={`pat_response_data_${index}`} className={`${index % 2 != 0 && 'bg-gray-50'}`}>
                          <td className="p-2">{response.questionNumber}</td>
                          <td className="p-2">{response.strand}</td>
                          <td className="p-2">{response.correctPercentage}</td>
                          <td className="p-2">{response.difficulty}</td>
                          <td className="p-2">
                            {response.correctAnswer}
                          </td>
                        </tr>
                      ))

                    }
                  </tbody>
                </table>
              </>
            }
          </>
      }
    </>
  )
})

export default PATTab;