import React, { useState, useEffect } from "react";
import Layout from "../shared-layout";
import BuildIcon from '@material-ui/icons/Build';
import { observer } from "mobx-react";
import { CircularProgress, MenuItem, Select, Tab, Tabs } from "@material-ui/core";
import TabPanel from "../tab-panel";
import useStores from "../../hooks/use-stores";
import { useTranslation } from 'react-i18next';
import NaplanTab from "./naplan-tab";
import PATTab from "./PAT-tab";
import VMCard from "../collapsed-card";

function tabProps(index: any, isActive) {
  return {
    id: `external-tab-${index}`,
    'aria-controls': `external-tabpanel-${index}`,
    className: `normal-case ${isActive ? 'text-barPrimary font-bold' : 'text-black'} `
  };
}

const ExternalTab = observer((() => {
  const { studentStore, academicStore } = useStores();
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState<number>(0);

  useEffect(() => {
    // if (studentStore.schoolNaplanData.length < 1) {
      studentStore.getDashboardSchoolNaplanData();
    // }

    // if (academicStore.patOverviewData.length < 1) {
      academicStore.getSchoolPATOverview();
    // }

    // if (academicStore.patOverviewDetail.length < 1) {
      academicStore.getSchoolPATDetail();
    // }
  }, []);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <VMCard
      className="mx-2"
      titleComponent={
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="external tabs example"
          TabIndicatorProps={{ style: { display: "none" } }}
        >
          <Tab label="NAPLAN" {...tabProps(0, 0 === tabValue)} />
          <Tab label="PAT" {...tabProps(1, 1 === tabValue)} />
        </Tabs>
      }
    >
      <TabPanel value={tabValue} index={0} paddingValue={0}>
        <NaplanTab />
      </TabPanel>

      <TabPanel value={tabValue} index={1} paddingValue={1}>
        <PATTab />
      </TabPanel>
    </VMCard>
  )
}));

export default ExternalTab;