import React, { useState, useEffect } from 'react';
import ReactEcharts from 'echarts-for-react';
import { observer } from 'mobx-react';
import { CHART_HEIGHT, CHART_WIDTH } from '../../constants/style';


const IESRadar = observer(({ dataOptions }) => {
    useEffect(()=>{
        console.log(dataOptions);
        
    }, [dataOptions])

    const option = {
        tooltip: { trigger: 'axis' },
        legend: {
            left: 'center',
            top: '0%',
            data: dataOptions.legend
        },
        radar: {
            radius: dataOptions.radius ? dataOptions.radius : '70%',
            indicator: dataOptions.indicator,
            axisName: {
                color: 'black'
            }
        },
        series: [
            {
                tooltip: {
                    trigger: 'item'
                },
                // name: 'Budget vs spending',
                name: dataOptions.name ? dataOptions.name : "",
                type: 'radar',
                data: dataOptions.series
            }
        ]
    };
    return (
        <ReactEcharts
            style={{
                height: '400px',
                width: CHART_WIDTH,
            }}
            option={option}
        />
    );
})

export default IESRadar;