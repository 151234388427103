import React, { useState, useEffect } from "react";
import Layout from "../shared-layout";
import BuildIcon from '@material-ui/icons/Build';
import { observer } from "mobx-react";
import { CircularProgress, MenuItem, Select, Tab, Tabs } from "@material-ui/core";
import TabPanel from "../tab-panel";
import useStores from "../../hooks/use-stores";
import IESRadar from "../charts/iesRadar";
import { toJS } from "mobx";
import { useStyles } from "../../constants/style";
import { useTranslation } from 'react-i18next';

const NaplanTab = observer((() => {
  const { studentStore } = useStores();
  const { t } = useTranslation();
  const classes = useStyles();
  const [naplanLegend, setNaplanLegend] = useState<any>();
  const [naplanIndicator, setNaplanIndicator] = useState<any>();
  const [naplanPrepareData, setNaplanPrepareData] = useState<any>();
  const [naplanData, setNaplanData] = useState<any>();
  const [naplanByYearLegend, setNaplanByYearLegend] = useState<any>();
  const [naplanByYearData, setNaplanByYearData] = useState<any>();
  const [naplanByYearLevelLegend, setNaplanByYearLevelLegend] = useState<any>();
  const [naplanByYearLevelData, setNaplanByYearLevelData] = useState<any>();
  const [yearInput, setYearInput] = useState<number>(0);
  const [yearLevelInput, setYearLevelInput] = useState<number>(0);
  const [yearList, setYearList] = useState<any>();
  const [yearLevelList, setYearLevelList] = useState<any>();

  useEffect(() => {
    // ==================== 
    // Radar Graph
    // ====================
    let tempNaplanIndicator = [];
    if (studentStore.schoolNaplanData.length > 0) {
      setNaplanPrepareData(studentStore.schoolNaplanData);
      setYearList([
        ... new Set(studentStore.schoolNaplanData.map(data => data.year))
      ]);
      setYearLevelList([
        ... new Set(studentStore.schoolNaplanData.map(data => data.yearLevel))
      ]);
      // Initial default option for two radar filter dropdowns
      setYearInput(studentStore.schoolNaplanData[0].year);
      setYearLevelInput(studentStore.schoolNaplanData[0].yearLevel);

      for (var data of studentStore.schoolNaplanData[0].data) {
        tempNaplanIndicator.push({
          name: data.strandName,
          max: 10
        })
      }

      // Reverse the array so that grammar & punctutation with be the first one to avoid overflow
      setNaplanIndicator(tempNaplanIndicator.reverse());
      processRadarData(studentStore.schoolNaplanData, 'all');
      processRadarData(studentStore.schoolNaplanData, 'byYear', studentStore.schoolNaplanData[0].year);
      processRadarData(studentStore.schoolNaplanData, 'byYearLevel', studentStore.schoolNaplanData[0].yearLevel);
    }
  }, [studentStore.schoolNaplanData]);

  const processRadarData = async (mapData: any, mode: 'all' | 'byYear' | 'byYearLevel', input?: string) => {
    let tempNaplanLegend = [];
    let tempFinalNaplanResult = [];
    for (var data of mapData) {
      if (mode == 'byYear' && data.year != input) continue;
      else if (mode == 'byYearLevel' && data.yearLevel != input) continue;
      else
        tempNaplanLegend.push(data.year + ' Y' + data.yearLevel);

      let tempNaplanValue = [];
      for (var value of data.data) {
        tempNaplanValue.unshift(value.schoolResultBand);
      }
      tempFinalNaplanResult.push({
        value: tempNaplanValue,
        name: data.year + ' Y' + data.yearLevel
      });
    }
    console.log(tempFinalNaplanResult);

    if (mode == 'all') {
      setNaplanLegend(tempNaplanLegend);
      setNaplanData(tempFinalNaplanResult);
    } else if (mode == 'byYear') {
      setNaplanByYearLegend(tempNaplanLegend);
      setNaplanByYearData(tempFinalNaplanResult);
    } else {
      setNaplanByYearLevelLegend(tempNaplanLegend);
      setNaplanByYearLevelData(tempFinalNaplanResult);
    }
  }

  const onChangeYear = (event: React.ChangeEvent<{ value: unknown }>) => {
    let selected = event.target.value as number;
    setYearInput(selected);
    processRadarData(naplanPrepareData, 'byYear', selected.toString());
  }

  const onChangeYearLevel = (event: React.ChangeEvent<{ value: unknown }>) => {
    let selected = event.target.value as number;
    setYearLevelInput(selected);
    processRadarData(naplanPrepareData, 'byYearLevel', selected.toString());
  }

  return (
    <>
      {
        studentStore.loadingSDashNaplan ? <div className="col-span-3 w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
          :
          <>
            <div className="grid grid-cols-2 px-4 mt-8">
              <div>
                <div className="mb-6 text-center">
                  <span className="text-barPrimary mr-2">{t('IN_YEAR')}</span>
                  {
                    yearList &&
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      className='p-0'
                      style={{ width: 100 }}
                      value={yearInput}
                      onChange={onChangeYear}
                    >
                      {
                        yearList.map((ay, index) => (
                          <MenuItem key={`year_list_${ay.id}_${index}`} value={ay}>
                            {+ay}
                          </MenuItem>
                        ))
                      }
                    </Select>
                  }
                </div>

                <IESRadar
                  dataOptions={{
                    radius: '55%',
                    legend: naplanByYearLegend,
                    indicator: naplanIndicator,
                    name: 'Naplan Performance',
                    series: naplanByYearData
                  }}
                />
              </div>

              <div>
                <div className="mb-6 text-center">
                  <span className="text-barPrimary mr-2">{t('FOR_YEAR_LEVEL')}</span>
                  {
                    yearLevelList &&
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      className={classes.root}
                      style={{ width: 70 }}
                      value={yearLevelInput}
                      onChange={onChangeYearLevel}
                    >
                      {
                        yearLevelList.map((ay, index) => (
                          <MenuItem key={`year_list_${ay.id}_${index}`} value={ay}>
                            {+ay}
                          </MenuItem>
                        ))
                      }
                    </Select>
                  }
                </div>

                <IESRadar
                  dataOptions={{
                    radius: '55%',
                    legend: naplanByYearLevelLegend,
                    indicator: naplanIndicator,
                    name: 'Naplan Performance',
                    series: naplanByYearLevelData
                  }}
                />
              </div>

            </div>

          </>
      }
    </>
  )
}));

export default NaplanTab;