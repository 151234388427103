import React, { useEffect, useState } from 'react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { observer } from 'mobx-react';
import SlideDown from 'react-slidedown';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const VMCard = observer(({ children, className = "", title = "", titleClassName = "", defaultClose = false, titleComponent = null }) => {
  const [dropdown, setDropdown] = useState<boolean>(defaultClose ? false : true);

  return (
    <>
      <div className={`border rounded-xl shadow-lg ${className}`}>
        <div className={`flex justify-between mr-4 ${titleClassName}`}>
          {titleComponent ? titleComponent : <div className={`text-barPrimary font-bold`}>{title}</div>}
          <button
            type="button"
            onClick={() => setDropdown(!dropdown)}
            className="text-gray-400"
          >
            {!dropdown ? <ExpandMoreIcon color="inherit" /> : <ExpandLessIcon color="inherit" />}
          </button>
        </div>
        <SlideDown
          className={'pure-menu pure-menu-scrollable '}
          closed={!dropdown}
        >
          {children}
        </SlideDown>
      </div>
    </>
  );
});

export default VMCard;