import React, { useState, useEffect } from 'react';
import ReactEcharts from 'echarts-for-react';
import { autorun } from 'mobx';
import { observer } from 'mobx-react';
import useStores from '../../hooks/use-stores';
import tailwindConfig from '../../../tailwind.config';
import resolveConfig from 'tailwindcss/resolveConfig';
import { BAR_RADIUS, getCurrentThemeColour, THEME_CLASS } from '../../constants/style';

const IESVBar = observer(({ dataAndSetting, callback = null }) => {
  const { behaviorStore } = useStores();
  const [option, setOption] = useState({});
  const [series, setSeries] = useState<any>([]);

  useEffect(() => {

  }, [])

  const configureOption = (data, setting) => {

    var dataZoomEnd = Math.round(setting.initialShow / data.names.length * 100);
    dataZoomEnd = (dataZoomEnd > 100) ? 100 : dataZoomEnd;

    var option_ = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      grid: {
        top: '8%',
        left: '3%',
        right: '4%',
        bottom: '10%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        axisLabel: {
          interval: 0,
          rotate: setting.xAxisLabelRotate  // rotate x axis label
        },
        data: data.names
      },
      yAxis: {
        type: 'value',
        max: setting.yMax,
      },
      dataZoom: [{
        show: setting.showDataZoom,
        type: 'inside',
        start: 0,
        end: dataZoomEnd
      }, {
        show: setting.showDataZoom,
        type: 'slider',
      }],
      series: [
        {
          name: 'Score',
          type: 'bar',
          color: setting.barColor,
          barWidth: setting.barWidth,
          barMaxWidth: setting.barMaxWidth,
          label: {
            show: true,
            position: 'top'
          },
          data: data.values,
          // itemStyle: { barBorderRadius: [BAR_RADIUS, BAR_RADIUS, 0, 0] }
        },
        dataAndSetting.secondBar && {
          name: 'School Score',
          type: 'bar',
          color: '#ed7474',
          barWidth: setting.barWidth,
          barMaxWidth: setting.barMaxWidth,
          label: {
            show: true,
            position: 'top'
          },
          data: dataAndSetting.secondBar,
          // itemStyle: { barBorderRadius: [BAR_RADIUS, BAR_RADIUS, 0, 0] }
        }
      ]
    };
    setOption(option_);
  }

  useEffect(() => {
    const {
      showDataZoom = true,
      initialShow = 999,
      xAxisLabelRotate = 90,
      barColor = THEME_CLASS[0].chartColorLibrary[0],
      barWidth = '70%',
      barMaxWidth = 30,
      yMax = undefined,
    } = dataAndSetting
    configureOption(dataAndSetting.data, {
      initialShow,
      xAxisLabelRotate,
      barColor,
      barWidth,
      barMaxWidth,
      showDataZoom,
      yMax,
    })
  }, [dataAndSetting]);


  return (
    <ReactEcharts
      style={{
        height: dataAndSetting.height,
        width: dataAndSetting.width,
        margin: '0 auto',
      }}
      onEvents={callback}
      option={option}
    />
  );
})

export default IESVBar;